.payment-analysis {
  padding: 60px 330px 60px 80px;
}
@media screen and (max-width: 768px) {
  .payment-analysis {
    padding: 0px;
  }
}

.ref {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.w-240 {
  width: 240px;
}

.pb-22 {
  margin-bottom: 22px;
  padding: 0;
}

.pb-69 {
  margin-bottom: 69px;
  padding: 0;
}

.payment-analysis p {
  padding: 0;
  margin: 0;
}

.rup-button {
  width: 100%;
}
