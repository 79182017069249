@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;800&display=swap");

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b434e;
  font-size: 14px;
}

code {
  font-family: "Work Sans", sans-serif;
}

/* width and height*/
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  border: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9ecf5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3cad9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107;
}

.flex-wrap {
  display: flex;
}

.w-231 {
  width: 231px;
}

.w-480 {
  width: 480px;
}

.pb-25 {
  padding-bottom: 25px;
}

.s-18 {
  gap: 18px;
}

.s-26 {
  gap: 26px;
}
