.MuiStepLabel-label.Mui-active {
    color: #ffc107 !important;
    font-weight: 500 !important;
  }
.school{
  background-color:  white;
  padding: 80px;
  padding-top:55px;

}
@media screen and (max-width: 768px) {
  .school {
    padding: 0px;

  }
}