@keyframes CustomSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.CustomSpin {
  animation: CustomSpin 2s linear infinite;
}

@keyframes pulse {
  100% {
    opacity: 1;
  }

  90% {
    opacity: .9;
  }

  80% {
    opacity: .8;
  }

  70% {
    opacity: .7;
  }

  60% {
    opacity: .6;
  }
  50% {
    opacity: .5;
  }
  40% {
    opacity: .4;
  }
  30% {
    opacity: .3;
  }
  20% {
    opacity: .2;
  }
  10% {
    opacity: .1;
  }
  0% {
    opacity: 0;
  }
}

.animate-pulse {
  animation: pulse 1s;
}

.base {
  position: absolute;
}
.baseDiv {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
 margin-top: 10%;
}