.logo {
  width: 270px;
  align-items: left;
  padding: 10px 20px;
  display: flex;
  justify-content: left;
}

.logo img {
  width: 63px;
}

.title {
  width: 70%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #8893a9;
}

.profile {
  width: 15%;
  display: flex;
  align-items: center;
  margin-left: 20px; /* Reduced margin for smaller screens */
}

.info {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.info p {
  margin: 0;
  padding-left: 20px;
}

.info p:first-child {
  padding-bottom: 6px;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #42474b;
}

.role {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #afafaf;
}

.category {
  color: #8893a9;
  margin: 0;
  padding-left: 10px; /* Ajuste o valor do padding esquerdo conforme desejado */
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.logout-button-container {
  margin-top: 25px;
  right: 10px; /* Adjusted margin for smaller screens */
}
