.personal-info h2 {
    color: #FFC107;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.personal-info p {
    color: #949494;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.3%;
    width: 800px;
}

.personal-info .profile-pic {
    width: 145px;
    height: 153px;
    margin: 0;
    margin-bottom: 21px;
}

.red-asterisk {
    color: #FB3F4A;
}

.flex-wrap {
    display: flex;
}

.w-231 {
    width: 231px;
}

.w-480 {
    width: 480px;
}

.pb-25 {
    padding-bottom: 25px;
}

.s-18{
    gap: 18px;
}

.s-26 {
    gap: 26px;
}