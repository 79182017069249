.academic-info h2 {
    color: #FFC107;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.academic-info p {
    color: #949494;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.3%;
    width: 800px;
}

.red-asterisk {
    color: #FB3F4A;
}

/* Estilos para telas grandes */
.large-screen-paragraph {
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
    /* Outros estilos desejados para telas grandes */
  }
  
  /* Estilos para telas pequenas */
  .small-screen-paragraph {
    text-align: left; /* ou qualquer outro estilo desejado para telas pequenas */
    font-size: 14px;
    line-height: 1.3;
    /* Outros estilos desejados para telas pequenas */
  }
  