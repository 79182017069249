.additional-info h2 {
    color: #FFC107;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.additional-info p {
    color: #949494;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.3%;
    width: 800px;
}

red-asterisk {
    color: #FB3F4A;
}

.flex-wrap {
    display: flex;
}

.w-231 {
    width: 231px;
}

.w-480 {
    width: 480px;
}

.pb-25 {
    padding-bottom: 25px;
}

.s-18 {
    gap: 18px;
}

.s-26 {
    gap: 26px;
}

.flex-row {
    display: flex;
    flex-direction: column;
}

.responsibility {
    color: #2F3843 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
}

*, ::before, ::after {
    box-sizing: content-box !important;

}

.radio-button {
    margin-bottom: 30px;
    margin-left: -20px;
}

.check-button {
    margin-bottom: 30px;
    margin-left: -20px;
}

.second-check {
    margin-top: -30px;
}

.radio-button ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 80px;
}

.check-button ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 600px;
}

.radio-button ul li {
    color: #373435;
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0;
}

.check-button ul li {
    color: #373435;
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0;
}

ul li input[type=radio],
ul li input[type=checkbox] {
    position: absolute;
    visibility: hidden;
}


.radio-button ul li label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 15px;
    padding: 35px 60px 25px 55px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}

.check-button ul li label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 15px;
    padding: 35px 1px 25px 55px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}

ul li:hover label {
    color: #373435;
}

.radio-button ul li .check {
    display: block;
    position: absolute;
    border: 5px solid #BDBDBD;
    border-radius: 5px;
    height: 16px;
    width: 16px;
    top: 30px;
    left: 20px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.check-button ul li .check {
    display: block;
    position: absolute;
    border: 5px solid #BDBDBD;
    border-radius: 5px;
    height: 16px;
    width: 16px;
    top: 30px;
    left: 20px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.radio-button ul li:hover .check {
    border: 5px solid #FFC107;
}

.check-button ul li:hover .check {
    border: 5px solid #FFC107;
}

.radio-button ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.check-button ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check,
input[type=checkbox]:checked~.check {
    border: 5px solid #FFC107;
}

input[type=radio]:checked~.check::before,
input[type=checkbox]:checked~.check::before {
    background: #FFC107;
}

input[type=radio]:checked~label,
input[type=checkbox]:checked~label {
    color: #373435;
}

