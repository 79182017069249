.button{
                 display: flex;
                    padding-Left: 610px;
                    padding-Bottom: 80px;
                    padding-Top: 130px;
                    gap: 10px;
                    position: relative;
                    right: 28px;
}
@media screen and (max-width: 768px) {
    .button{
      padding: 15px;
      padding-Top: 50px;
  
    }
  }