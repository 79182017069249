.enrollRightSide img {
    width: 90px;
    margin: 30px 0;
}

.information {
    margin-top: 49px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 17px;

}

.information img {
    width: 50px !important;
    height: 50px;
}


.enrollLeftSide {
    min-height: 100vh;
    height: 100%;
    background-color: #003B76;
    position: fixed;
}

.enrollLeftSide img {
    width: 100%;
    background-size: cover;
}

.enrollLeftSide .enrollLeftSideContent {
    padding: 0 42px;
}

.enrollLeftSide h2 {
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.information-contend {
    margin-top: -20px;
    margin-left: 70px;

}

.information-contend p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.3%;
}

.contact-group {
    display: flex;
    align-items: center;
    gap: 12px;
}

.contact-group:first-child {
    margin-top: 30px;
    margin-bottom: 35;
}

.contact-group img {
    width: 34px;
    height: 34px;
}

.contact-group p {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.3%;
}

.button-group {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 17px;
    margin-bottom: 50px;
}
@media (max-width: 600px) {
    .container-da-tabela-responsiva {
      overflow-x: auto;
    }
  }

  .hidden-xs {
    display: block; /* Exibe o elemento em telas maiores (sm e acima) */
  }
  
  @media (max-width: 600px) {
    /* Esconde o elemento em telas menores (menos de 600px de largura) */
    .hidden-xs {
      display: none;
    }
  }

  .srch {
    width: 150px;
    height: 15px;
    padding: 5px;
    font-size: 15px;
    border: 1px solid #ccc;
    position: absolute;
    left: 400px;
  }

