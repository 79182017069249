nav {
    padding: 10px 50px;
    border-bottom: 1px solid #ccc
}

nav a {
    padding: 5px;
}

aside {
    padding: 20px;
    border-right: 1px solid #ccc
}

.content {
    width: 100%;
    background-color: #F8FAFD;
    margin-top: 60px;
}

.midle-section .content {
    padding: 100px 130px
}

