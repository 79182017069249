.enroll-analyse-container {
    max-width: 100%;
}

h2 {
    color: #212529;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: -20px;
    margin-bottom: 24px;
    padding: 10px; 
}

.mother-flex {
    display: flex;
}

.left-side {
    width: 13%;
    background-color: #fff;
    padding: 40px;
    flex: 1;
    position: fixed;
 
}

.divider {
    width: 0.5%;
    background-color: #F8FAFD;
    height: 90vh;

}

.right-side {
    width: 79.6%;
    background-color: #fff;
    flex: 1;
    padding-left: 40px;
    margin-left: 290px; /* Deixa um espaço para o lado esquerdo fixo */
    
    z-index: 0; 
}

.step-title {
    color: #8893A9;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.button-container-right { 
    margin-right: 20px;
   text-align: right;
   margin-top: 20px;

}

body {
    overflow-x: hidden; /* Oculta a barra de rolagem horizontal */
  }
  @media screen and (max-width: 768px) {
.right-side{
    margin-left: 0px;
    padding-left: 0px;
}

  }