

/* Calendar.css */

.calendar-container {
  text-align: center;
  width: 250px; /* Adjust the width to your desired size */
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 1px; /* Aumentando um pouco o espaçamento interno */
  position: relative;
}

/* Add more styles as needed */



.calendar-header {
  font-size: 1.1rem; /* Adjust the font size to your desired size */
  font-weight: bold;
  margin-bottom: 8px; /* Ajustando a margem inferior */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.header-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.header-buttons button {
  margin-left: 8px; /* Ajustando a margem do botão */
  border: none;
  background-color: transparent;
}

.calendar-weekdays {
  display: flex;
  justify-content: space-between;
  padding: 8px 0; /* Ajustando o padding */
}

.weekday {
  flex: 1;
  text-align: center;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.calendar-day {
  text-align: center;
  padding: 8px; /* Ajustando o padding */
  position: relative;
}

.current-day {
  background-color: #FB3F4A;
  color: white;
  border-radius: 50%;
}
