.subjects-container {
  width: 100%;
  min-height: 500px;
  margin-top: 40px;
  border: 1px solid #e4ebf5;
  margin-left: 20px;
}

.subjects-container .header {
  background-color: #e4ebf5;
  padding: 19px;
}

.subjects-container .header .title {
  color: #42474b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.subjects-container .body {
  padding: 19px;
}

.subjects-container .body .subjects-wraper {
  padding: 6px 10px !important;
  background-color: #e9ecf5;
  border-radius: 6px;
  margin-bottom: 15px;
  width: -moz-fit-content;
  width: fit-content;
}

.subject {
  background-color: #e9ecf5;
  max-width: max-content;
  padding: 0.5rem;
  margin: 0.5rem 1.5rem 1rem 0.5rem;
  border-radius: 0.375rem;
  color: #42474b;
}

/*Semester content*/
.self-center {
  align-self: center;
}
.ml-3 {
  margin-left: 0.75rem /* 12px */;
}
.ml-2 {
  margin-left: 0.5rem;
}

.file-icon {
  margin-top: 9.5px;
  margin-bottom: 9.5px;
}
.file-bg {
  background-color: #e4ebf5;
}
.p-4 {
  padding: 1rem /* 16px */;
}

/*Icons*/
.activeIcon {
  align-self: center;
  border-style: solid;
  border-width: 1.8px;
  border-color: black;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 4px;
}
.semesterIcon {
  width: 12;
  height: 12;
}
.deleteIcon {
  cursor: pointer;
}
