.history-analyis {
    padding: 60px 320px 60px 80px;
}

.step {
    width: 656px;
    height: 71px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.20);
    padding: 11px 20px;
}

.step .title {
    color: #003B76;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-bottom: 6px;
}

.step .date {
    color: #8893A9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}