.logo-confirmation {
    width: 113px;
    height: 106px;
    margin-bottom: 43px;
}

.enroll-confirmation {
    background-color: #F8F9FA;
    padding: 38px 100px;
}

.enroll-confirmation h2 {
    color: #FFC107;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.flex {
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-bottom: -10px;
    width: 650px;
}

.flex div {
    width: 400px;
}

.enroll-confirmation h3 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.enroll-confirmation p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.enroll-confirmation .confirmation-body {
    margin-bottom: 50px;
}

.confirmation-footer {
    align-items: center;
    gap: 45px;
}

.confirmation-footer img {
    width: 93px;
    height: 86px;
}

.confirmation-footer div:last-child {
    width: 430px;
}

.confirmation-footer p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
}

.back-button {
    margin-right: 180px;
    margin-top: 102px;
}

.back-button span {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.enrollRightSide img {
    width: 90px;
    margin: 30px 0;
}

.information {
    margin-top: 49px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 17px;

}

.confirmation-left-side>img {
    width: 100%;
}

.information img {
    width: 50px !important;
    height: 50px;
}

.confirmation-left-side {
    min-height: 100vh;
    height: 100%;
    background-color: #003B76;
}


.confirmation-content {
    padding: 0 42px;
}

.confirmation-left-side h2 {
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.information-contend {
    margin-top: -20px;
    margin-left: 70px;

}

.information-contend p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.3%;
}